.d-header-logo,
.d-footer-logo {
    display: block;
    width: 3.5rem;
    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
    @include breakpoint(xl) {
        width: 4.3333rem;
    }
}

.d-mobile-menu-logo {
    width: 3.5rem;
    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
    @include breakpoint(xl) {
        width: 4.3333rem;
    }
}

.d-history-logo {
    width: 38.59375vw;
    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.d-img-cover {
    object-fit: cover;
    font-family: "object-fit: cover";
    height: 100%;
    width: 100%;
}

.d-abf {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
}
