.d-photos {
    display: block;
    padding-top: 12.89583333vw;
    position: relative;
}

.d-photo-slider {
    color: $color-white;
    padding-bottom: 90vw;
    position: relative;
    margin-bottom: 3.2em;
    margin-right: var(--page-padding);
    position: relative;
    @include breakpoint(md) {
        margin-bottom: 0;
        padding-bottom: 61.35416667vw;
    }
}

.d-photo-slider__item {
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    @include breakpoint(md) {
        flex-flow: row nowrap;
    }
}

.d-photo-slider__left {
    display: none;
    flex-basis: calc(#{columns(5)} + #{var(--page-padding)});
    max-width: calc(#{columns(5)} + #{var(--page-padding)});
    @include breakpoint(md) {
        display: block;
    }
}

.d-photo-slider__right {
    padding-left: columns(1);
    position: relative;
    width: 100%;
    @include breakpoint(md) {
        flex-basis: columns(7);
        max-width: columns(7);
    }
}

.d-photo-slider__image--1,
.d-photo-slider__image--2 {
    overflow: hidden;
    position: relative;
}

.d-photo-slider__image--2 {
    width: columns(9);
    @include breakpoint(md) {
        width: columns(4);
    }
}

.d-photo-slider-image-1 {
    background-color: green;
    padding-bottom: 145%;
    position: relative;
    will-change: transform;
    z-index: 2;
}

.d-photo-slider-image-2 {
    background-color: green;
    padding-bottom: 110%;
    position: relative;
    will-change: transform;
    @include breakpoint(md) {
        padding-bottom: 35.4166667vw;
    }
}

.d-photo-slider__title {
    margin-bottom: 4.9479166667vw;
    width: columns(4);
}

.d-photo-slider__nav {
    width: calc(61.35416667vw - 9.8958333334vw);
    position: absolute;
    top: 100%;
    left: calc(100% - #{var(--page-padding)} - #{columns(0.5)});
    text-align: center;
    transform: rotate(-90deg);
    transform-origin: 0px 0px;
    z-index: 9999;
    @include breakpoint(md) {
        left: calc(100% - 4.947916665vw);
    }
}

.d-photo-slider-nav__item {
    cursor: pointer;
}

.d-photo-slider-nav__item:nth-child(1) {
    margin-right: 4.947916665vw;
}

.d-photo-slider-nav__item:nth-child(2) {
    margin-left: 4.947916665vw;
}

.d-photo-slider-image__overlay {
    background-color: $color-black;
    position: absolute;
    left: -5px;
    right: -5px;
    bottom: -5px;
    top: -5px;
    z-index: 0;
}

.d-photo__link {
    margin-left: var(--page-padding);
    top: 100vw;
    z-index: 300;
    @include breakpoint(md) {
        bottom: 0;
        margin-top: 0;
        margin-left: 0;
        position: absolute;
        top: auto;
        left: calc(#{columns(6)} + #{var(--page-padding)});
    }
}
