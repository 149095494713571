$animationEasingSmooth: cubic-bezier(0.215, 0.61, 0.355, 1);

.d-image-animate-in--from-right.is-hidden {
    .d-image-animate-in__image {
        clip-path: inset(0px 0px 0px 100%);
        transform: translateX(25%);
    }
    img {
        transform: scale(1.1);
    }
}

.d-image-animate-in--from-left.is-hidden {
    .d-image-animate-in__image {
        clip-path: inset(0px 100% 0px 0px);
        transform: translateX(-25%);
    }
    img {
        transform: scale(1.1);
    }
}

.d-image-animate-in--from-bottom.is-hidden {
    .d-image-animate-in__image {
        clip-path: inset(10% 0px 0px 0px);
        transform: translateY(25%);
    }
    img {
        transform: scale(1.1);
    }
}

.d-image-animate-in--from-right {
    .d-image-animate-in__image {
        clip-path: inset(0px 0px 0px 0px);
        transform: translateX(0);
        transform-origin: top right;
    }
    img {
        transform: scale(1);
    }
}

.d-image-animate-in--from-left {
    .d-image-animate-in__image {
        clip-path: inset(0px 0px 0px 0px);
        transform: translateX(0);
        transform-origin: top left;
    }
    img {
        transform: scale(1);
    }
}

.d-image-animate-in--from-bottom {
    .d-image-animate-in__image {
        clip-path: inset(0px 0px 0px 0px);
        transform: translateY(0);
        transform-origin: top center;
    }
    img {
        transform: scale(1);
    }
}

.d-image-animate-in__image {
    overflow: hidden;
}

.d-gbnf-animate-in__slider.is-hidden .d-gbnf-animate-in__image {
    transform: translateY(300px);
    .d-gbnf-photo-slider-photo {
        clip-path: inset(10% 0px 0px 0px);
    }
    img {
        transform: scale(1.2);
    }
}

.d-gbnf-animate-in__slider.is-hidden .d-gbnf-animate-in__title {
    opacity: 0;
    transform: translateY(300px);
}

.d-image-animate-in__image,
.d-image-animate-in__image img {
    will-change: transform;
    transition: all 2.2s;
    transition-timing-function: $animationEasingSmooth;
}

.d-gbnf-animate-in__title,
.d-gbnf-animate-in__image,
.d-gbnf-animate-in__image img {
    will-change: transform;
    transition: all 1.4s;
    transition-timing-function: $animationEasingSmooth;
}

.d-gbnf-animate-in__title,
.d-gbnf-animate-in__image {
    transition-delay: 0.2s;
}

.d-gbnf-name-slider-name:nth-child(1) .d-gbnf-animate-in__title {
    transition-delay: 0.2s;
}

.d-gbnf-name-slider-name:nth-child(2) .d-gbnf-animate-in__title {
    transition-delay: 0.4s;
}

.d-gbnf-name-slider-name:nth-child(3) .d-gbnf-animate-in__title {
    transition-delay: 0.6s;
}

.d-gbnf-name-slider-name:nth-child(4) .d-gbnf-animate-in__title {
    transition-delay: 0.8s;
}

.d-animate-in__title.is-hidden .word {
    opacity: 0;
    transform: translateY(150px);
}

.d-animate-in__title .word {
    will-change: transform;
    transition: all 1.2s;
    transition-timing-function: $animationEasingSmooth;
}
