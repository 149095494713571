#intro {
    position: relative;
}

.d-intro {
    background-color: $color-black;
    padding-top: 3.555rem;
    position: relative;
    margin-top: -1px;
    overflow-x: hidden;
    @include breakpoint(sm) {
        padding-top: 6rem;
    }
    @include breakpoint(md) {
        padding-bottom: 65%;
        padding-top: 0;
        text-align: right;
    }
    @include breakpoint(xl) {
        padding-bottom: 58%;
    }
}

.d-intro__text {
    display: block;
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);
    position: relative;
    z-index: 2;
    width: columns(11);
    @include breakpoint(sm) {
        padding-right: 0;
        width: columns(9);
    }
    @include breakpoint(md) {
        padding-left: columns(1);
        position: absolute;
        left: var(--page-padding);
        top: 26.3125vw;
        width: columns(7.5);
    }
    @include breakpoint(xl) {
        top: 20.3125vw;
    }
}

.d-intro__title {
    text-align: left;
}

.d-intro__link {
    color: $color-white;
    margin-top: 2.779rem;
    @include breakpoint(md) {
        margin-right: 3.6665vw;
        margin-top: 4.98958333vw;
    }
}

.d-intro__image {
    position: relative;
    margin-top: -6.666em;
    z-index: 1;
    @include breakpoint(md) {
        position: absolute;
        margin-top: 0;
    }
}

.d-intro-image--right {
    left: 0;
    right: 0;
    bottom: 0;
    @include breakpoint(md) {
        left: auto;
        bottom: -4.947916667vw;
        width: columns(7);
    }
}

.d-intro-image-right {
    padding-bottom: 65%;
    position: relative;
    @include breakpoint(md) {
        padding-bottom: 96%;
    }
}

.d-intro-image--left {
    bottom: -4.427083333vw;
    left: var(--page-padding);
    width: columns(2);
}

.d-intro-image-left {
    padding-bottom: 100%;
    position: relative;
}
