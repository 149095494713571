.d-button {
    @include button();
}

// prettier-ignore
.d-hamburger-box {
    @include fluid-properties(("width"), 30px, 768px, 36px, $design-width, 38px, $breakpoint-max);
    align-items: center;
    display: flex;
    z-index: 2;
}

.d-hamburger-button {
    opacity: 1 !important;
    top: 3em;
    right: calc(#{var(--page-padding)} - 0.9em);
    position: fixed;
    z-index: 9000;
    @include breakpoint(sm) {
        top: 3.5em;
    }
}

.d-hamburger-button .d-hamburger-button__inner,
.d-hamburger-button.is-active .d-hamburger-button__inner {
    background-color: $color-accent;
    &:before,
    &:after {
        background-color: $color-accent;
    }
}

.d-hamburger-button__inner {
    border-radius: 0;
    height: 2px;
    width: 100%;
    &:before,
    &:after {
        border-radius: 0;
        height: 2px;
        width: 100%;
    }
    &:before {
        top: -9px;
    }
    &:after {
        bottom: -9px;
    }
}
