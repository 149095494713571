.d-mobile-menu-overlay {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 9000;
    &.is-hidden {
        display: none;
    }
}

.d-mobile-menu-overlay__background {
    background-color: $color-black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.d-mobile-menu {
    height: 100%;
    margin-top: 150px;
    padding-top: 45px;
    position: relative;
    text-align: center;
    width: 100%;
    &:before {
        content: "";
        background-color: $color-white;
        display: block;
        height: 1px;
        opacity: 0.4;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}

.d-mobile-menu__link {
    @include type-scale--mobile-menu-link;
    color: $color-white;
    display: inline-block;
    font-weight: 700;
    margin: 0.3em 0;
}
