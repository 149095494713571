.d-footer {
    background-color: $color-black;
    color: $color-white;
}

.d-footer__top,
.d-footer__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    @include breakpoint(md) {
        flex-direction: row;
        text-align: left;
    }
}

.d-footer__col {
    margin-bottom: 3em;
    @include breakpoint(md) {
        margin-bottom: 0;
    }
}

.d-footer__label {
    @include type-scale--header-link;
    font-weight: normal;
}

.d-footer__label.d-footer-label--bold {
    font-weight: 700;
}

.d-footer__link {
    @include type-scale--footer-link;
    font-weight: bold;
}

.d-footer__bottom,
.d-legal-link {
    @include type-scale--footer-legal-link;
    font-weight: normal;
}

.d-footer__logo {
    margin: 0 auto;
    @include breakpoint(md) {
        margin: 0;
    }
}

.d-legal-link {
    margin-left: 5.7vw;
    @include breakpoint(md, true) {
        display: block;
        margin-left: 0;
    }
}
