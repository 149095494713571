.d-support {
    padding-top: 8.802083333vw;
    position: relative;
    overflow-x: hidden;
}

.d-support__sticky-header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 3em;
    @include breakpoint(md) {
        padding-bottom: 0;
    }
}

.d-support__block {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    @include breakpoint(md) {
        flex-direction: row;
    }
}

.d-support__content,
.d-support__photos {
    display: block;
    flex: 1;
    position: relative;
    width: 100%;
    @include breakpoint(md) {
        flex-basis: columns(6);
        max-width: columns(6);
    }
}

.d-support__text,
.d-support__cta {
    @include breakpoint(md) {
        padding-left: columns(1);
    }
}

.d-support__cta {
    @include type-scale--footer-link;
    font-weight: bold;
}

.d-support__photos {
    padding-left: columns(1);
    margin-top: 10.41666667vw;
    @include breakpoint(md, true) {
        margin-top: 5rem;
        padding-left: 0;
    }
}

.d-support__photo-box {
    padding-bottom: 76vw;
    position: relative;
    @include breakpoint(md) {
        padding-bottom: 33.85416667vw;
    }
}

.d-support__photo {
    display: block;
    position: absolute;
    z-index: 1;
}

.d-support__photo-a {
    top: 0;
    left: 0;
    width: columns(11);
    @include breakpoint(md) {
        width: columns(5);
    }
}

.d-support-photo-a {
    display: block;
    padding-bottom: 76%;
    position: relative;
    width: 100%;
}

.d-support__photo-b {
    bottom: columns(-0.4);
    right: columns(3);
    width: columns(5);
    @include breakpoint(md) {
        right: columns(1);
        width: columns(2);
    }
}

.d-support-photo-b {
    display: block;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
}

.d-support__link {
    display: inline-block;
    line-height: 1.555em;
    position: relative;
}

.d-support__shape {
    background-color: $color-accent;
    display: block;
    position: absolute;
    left: columns(2);
    bottom: columns(1);
    height: columns(3.1);
    width: columns(3);
    z-index: 0;
    @include breakpoint(md) {
        height: columns(1.1);
        width: columns(1);
        left: columns(1.25);
        bottom: columns(0.4);
    }
}

.d-support__slider {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding: 0 0 0 calc(#{var(--page-padding)} + #{columns(2)});
    position: relative;
}

.d-support-slider__title {
    position: absolute;
    left: var(--page-padding);
    top: 100%;
    transform: rotate(-90deg);
    transform-origin: 0px 0px;
}

.d-support-slider-item__trader {
    @include type-scale--header-link;
    font-weight: normal;
    margin-bottom: 0.35em;
}

.d-support-slider-item__title {
    @include type-scale--footer-link;
    font-weight: bold;
}

.d-support-slider__slider {
    width: columns(10);
}

.d-support-slider-item {
    @include breakpoint(md, true) {
        padding-left: 2em;
    }
}

.d-support-slider__navigation {
    margin-top: 3.5em;
    @include breakpoint(md, true) {
        padding-left: 2em;
    }
}

.d-support-slider-navigation__item {
    cursor: pointer;
    &:nth-child(1) {
        margin-right: 2.4em;
    }
}
