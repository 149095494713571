// Reboot
//
// Normalization of HTML elements, manually forked from Bootstrap 4 (which is a fork of Normalize.css) to remove
// styles targeting irrelevant browsers while applying new styles.

// Document
//
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
//    we force a non-overlapping, non-auto-hiding scrollbar to counteract.
// 6. Change the default tap highlight to be completely transparent in iOS.

*,
*::before,
*::after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

html {
    width: 100%;
    box-sizing: border-box;
    font-family: sans-serif; // 2
    line-height: 1.15; // 3
    -webkit-text-size-adjust: 100%; // 4
    -ms-text-size-adjust: 100%; // 4
    -ms-overflow-style: scrollbar; // 5
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // 6
}

// IE10+ doesn't honor `<meta name="viewport">` in some cases.
@at-root {
    @-ms-viewport {
        width: device-width;
    }
}

// Shim for "new" HTML5 structural elements to display correctly (IE10, older browsers)
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. Assumes the browser default, typically `16px`;
// 3. As a best practice, apply a default `background-color`.
// 4. Set an explicit initial text-align value so that we can later use the
//    the `inherit` value on things like `<th>` elements.

body {
    margin: 0; // 1
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem; // 2.
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    text-align: left; // 4
    background-color: #fff; // 3
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Credit: https://github.com/suitcss/base
[tabindex="-1"]:focus {
    outline: 0 !important;
}

// Content grouping
//
// 1. Add the correct box sizing in Firefox.
// 2. Show the overflow in Edge and IE.

hr {
    box-sizing: content-box; // 1
    height: 0; // 1
    overflow: visible; // 2
}

//
// Typography
//

// Remove margins from headings
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

// Reset margins on paragraphs
p {
    margin: 0;
}

// Abbreviations
//
// 1. Remove the bottom border in Firefox 39-.
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
// 3. Add explicit cursor to indicate changed behavior.
// 4. Duplicate behavior to the data-* attribute for our tooltip plugin

abbr[title],
abbr[data-original-title] {
    // 4
    text-decoration: underline; // 2
    text-decoration: underline dotted; // 2
    cursor: help; // 3
    border-bottom: 0; // 1
}

address {
    margin: 0;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin: 0;
}

blockquote {
    margin: 0;
}

dfn {
    font-style: italic; // Add the correct font style in Android 4.3-
}

b,
strong {
    font-weight: bolder; // Add the correct font weight in Chrome, Edge, and Safari
}

small {
    font-size: 80%; // Add the correct font size in all browsers
}

//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
//

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}

//
// Links
//

a {
    background-color: transparent; // Remove the gray background on active links in IE 10.
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
}

//
// Code
//

pre,
code,
kbd,
samp {
    font-family: monospace, monospace; // Correct the inheritance and scaling of font size in all browsers.
    font-size: 1em; // Correct the odd `em` font sizing in all browsers.
}

pre {
    margin: 0;
    // Don't allow content to break outside
    overflow: auto;
    // We have @viewport set which causes scrollbars to overlap content in IE11 and Edge, so
    // we force a non-overlapping, non-auto-hiding scrollbar to counteract.
    -ms-overflow-style: scrollbar;
}

//
// Figures
//

figure {
    // Apply a consistent margin strategy (matches our type styles).
    margin: 0;
}

//
// Images and content
//

img {
    vertical-align: middle;
    border-style: none; // Remove the border on images inside links in IE 10-.
}

svg:not(:root) {
    overflow: hidden; // Hide the overflow in IE
}

//
// Tables
//

table {
    border-collapse: collapse; // Prevent double borders
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #e0eaf5;
    text-align: left;
    caption-side: bottom;
}

th {
    // Matches default `<td>` alignment by inheriting from the `<body>`, or the
    // closest parent with a set `text-align`.
    text-align: inherit;
}

//
// Forms
//

label {
    // Allow labels to use `margin` for spacing.
    display: inline-block;
    margin: 0;
}

// Remove the default `border-radius` that macOS Chrome adds.
//
// Details at https://github.com/twbs/bootstrap/issues/24093
button {
    border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0; // Remove the margin in Firefox and Safari
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible; // Show the overflow in Edge
}

button,
select {
    text-transform: none; // Remove the inheritance of text transform in Firefox
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
html [type="button"], // 1
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; // 2
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box; // 1. Add the correct box sizing in IE 10-
    padding: 0; // 2. Remove the padding in IE 10-
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    // Remove the default appearance of temporal inputs to avoid a Mobile Safari
    // bug where setting a custom line-height prevents text from being vertically
    // centered within the input.
    // See https://bugs.webkit.org/show_bug.cgi?id=139848
    // and https://github.com/twbs/bootstrap/issues/11266
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto; // Remove the default vertical scrollbar in IE.
    // Textareas should really only resize vertically so they don't break their (horizontal) containers.
    resize: vertical;
}

fieldset {
    // Browsers set a default `min-width: min-content;` on fieldsets,
    // unlike e.g. `<div>`s, which have `min-width: 0;` by default.
    // So we reset that to ensure fieldsets behave more like a standard block element.
    // See https://github.com/twbs/bootstrap/issues/12359
    // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
    min-width: 0;
    // Reset the default outline behavior of fieldsets so they don't affect page layout.
    padding: 0;
    margin: 0;
    border: 0;
}

// 1. Correct the text wrapping in Edge and IE.
// 2. Correct the color inheritance from `fieldset` elements in IE.
legend {
    display: block;
    width: 100%;
    max-width: 100%; // 1
    padding: 0;
    margin: 0;
    line-height: inherit;
    color: inherit; // 2
    white-space: normal; // 1
}

progress {
    vertical-align: baseline; // Add the correct vertical alignment in Chrome, Firefox, and Opera.
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // `.form-control` class can properly style them. Note that this cannot simply
    // be added to `.form-control` as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    outline-offset: -2px; // 2. Correct the outline style in Safari.
    -webkit-appearance: none;
}

//
// Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
//

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

//
// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
//

::-webkit-file-upload-button {
    font: inherit; // 2
    -webkit-appearance: button; // 1
}

//
// Correct element displays
//

output {
    display: inline-block;
}

summary {
    display: list-item; // Add the correct display in all browsers
    cursor: pointer;
}

template {
    display: none; // Add the correct display in IE
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
// Needed for proper display in IE 10-.
[hidden] {
    display: none !important;
}
