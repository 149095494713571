// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
/* purgecss start ignore */

@font-face {
    font-family: "Quincy CF Black";
    src: url("../fonts/Quincy CF Black.woff2") format("woff2"),
        url("../fonts/Quincy CF Black.woff") format("woff"),
        url("../fonts/Quincy CF Black.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}

/* purgecss end ignore */
