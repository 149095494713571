// -----------------------------------------------------------------------------
// This file contains all basic styles related to the layout of the site/application.
// -----------------------------------------------------------------------------
.d-container {
    padding: 0 var(--page-padding);
}

.d-spacer {
    width: 100%;
}

.d-spacer--small {
    @include fluid(("height"), 20px, 20px);
}

// prettier-ignore
.d-spacer--medium {
    @include fluid(("height"), 35px, 50px);
}

// prettier-ignore
.d-spacer--default {
    @include fluid(("height"), 35px, 75px);
}


// prettier-ignore
.d-spacer--large {
    @include fluid(("height"), 65px, 205px);
}

// prettier-ignore
.d-spacer--xlarge {
    @include fluid(("height"), 80px, 290px);
}

//Simple Lightbox styling
.sl-overlay {
    background-color: $color-black;
}

.sl-wrapper .sl-close {
    color: $color-white;
}