// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
/* purgecss start ignore */
// prettier-ignore
html,
body {
    overflow: hidden;
}
// prettier-ignore
:root {
    --page-padding: #{$page-padding-mobile};
    @include breakpoint(md) {
        --page-padding: #{$page-padding};
    }
}
// prettier-ignore
#app {
    position: relative;
    max-width: 100vw;
}
// prettier-ignore
.d-smoothscrollbar {
    height: 100vh;
    will-change: transform;
    backface-visibility: hidden;
    perspective: 1000;
}
.d-intro,.d-history {
    overflow: hidden;
}