// -----------------------------------------------------------------------------
// This file contains all application-wide Sass functions.
// -----------------------------------------------------------------------------

/* purgecss start ignore */
// prettier-ignore
@function fluid-value($min-value: 12px, $lower-breakpoint: 350px, $max-value: 22px, $upper-breakpoint: 1200px) {
    @return calc(
        #{$min-value} + 
        #{( ($max-value / ($max-value * 0 + 1)) -  ($min-value / ($min-value * 0 + 1)))} * 
        ((100vw - #{$lower-breakpoint}) / #{(($upper-breakpoint / ($upper-breakpoint * 0 + 1)) - ($lower-breakpoint / ($lower-breakpoint * 0 + 1)))})
    );
}

// prettier-ignore
@function columns($number) {
    @return calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * #{$number});
}
/* purgecss end ignore */
