.d-default-link {
    display: inline-block;
    position: relative;
    &:after {
        content: "";
        @include fluid-properties(
            ("height"),
            3px,
            768px,
            4px,
            $design-width,
            4px,
            $breakpoint-max
        );
        @include fluid-properties(
            ("width"),
            40px,
            768px,
            75px,
            $design-width,
            75px,
            $breakpoint-max
        );
        background-color: $color-accent;
        display: block;
        bottom: 0;
        left: 0;
        right: 0;
        transition: width 0.8s $animationEasingSmooth;
        will-change: transform;
    }
    &:hover:not(.d-events-slider-nav__item, .d-photo-slider-nav__item) {
        &:after {
            width: 100%;
        }
    }
}

.d-default-link--large {
    @include type-scale--footer-link;
}
