.d-gbnf {
    position: relative;
    overflow-x: hidden;
}

.d-gbnf__header {
    text-align: center;
}

.d-gbnf-name-slider,
.d-gbnf-photo-slider {
    height: 55.46875vw;
    width: 100vw;
}

.d-gbnf-name-slider {
    position: relative;
}

.d-gbnf-photo-slider {
    position: absolute;
    top: 26vw;
    @include breakpoint(md) {
        top: 24.04166667vw;
    }
}

.d-gbnf-name-slider__item,
.d-gbnf-photo-slider__item {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    will-change: transform;
}

.d-gbnf-name-slider__item:not(.active) {
    .d-gbnf-name-slider-name__inner {
        opacity: 0;
        transform: translateY(100%);
    }
    .d-gbnf-name-slider__lifespan {
        opacity: 0;
        transform: translateY(-20%);
    }
}

.d-gbnf-name-slider-item {
    position: relative;
}

.d-gbnf-name-slider__lifespan {
    @include type-scale--body-small;
    color: $color-accent;
    font-weight: 700;
    margin-top: -2em;
    text-align: center;
    @include breakpoint(md) {
        margin-top: 0;
    }
}

.d-gbnf-name-slider-name {
    color: $color-white;
    display: block;
    width: calc(100vw * 2);
    text-align: center;
    transform: translateX(-50vw);
}

.d-gbnf-name-slider__name {
    padding-bottom: 10.4166667vw;
}

.d-gbnf-name-slider-name {
    overflow: hidden;
    padding-bottom: 1.4rem;
    font-family: $fontstack-bold;
    font-size: 12vw;
    line-height: 0.95em;
    @include breakpoint(md) {
        @include fluid(("font-size"), 40px, 140px);
    }
}

.d-gbnf-name-slider-name__inner {
    will-change: transform;
}

.d-gbnf-photo-slider {
    z-index: 900;
}

.d-gbnf-photo-slider__photo {
    position: absolute;
    top: 11vw;
    left: 50vw;
    overflow: hidden;
    width: 60vw;
    transform: translateX(-50%);
    @include breakpoint(md) {
        top: 0;
        width: 42.29166667vw;
    }
}

.d-gbnf-photo-slider-photo {
    height: 60vw;
    position: relative;
    will-change: transform;
    @include breakpoint(md) {
        height: auto;
        //height: 45.88541667vw;
        padding-bottom: 100%;
    }
}
