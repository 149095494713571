.d-history {
    padding-top: 12.5vw;
    position: relative;
    overflow-x: hidden;
}

.d-history__sticky-header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.d-history__sticky-header--mobile {
    @include breakpoint(md) {
        display: none;
    }
}

.d-history__year {
    @include type-scale--title-medium;
    font-weight: 700;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.d-history__block {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    @include breakpoint(md) {
        flex-direction: row;
    }
}

.d-history__content,
.d-history__photos {
    display: block;
    flex: 1;
    position: relative;
    width: 100%;
    @include breakpoint(md) {
        flex-basis: columns(6);
        max-width: columns(6);
    }
}

.d-history__content {
    z-index: 10;
    @include breakpoint(md, true) {
        margin-top: 3rem;
        order: 2;
    }
}

.d-history__photos {
    @include breakpoint(md, true) {
        margin-top: 3rem;
    }
}

.d-history__photo {
    display: block;
    position: absolute;
    z-index: 1;
}

.d-history__shape {
    background-color: $color-accent;
    display: block;
    position: absolute;
    z-index: 0;
}

/* History block a */

.d-history-block--a {
    align-items: center;
}

.d-history-block-a__content {
    .d-history__text {
        @include breakpoint(md) {
            padding-left: columns(1);
        }
    }
    @include breakpoint(md) {
        padding-right: columns(1);
    }
}

.d-history-block-a__photo-box {
    padding-bottom: 76vw;
    position: relative;
    @include breakpoint(md) {
        padding-bottom: 38.75vw;
    }
}

.d-history-block-a__photos {
    padding-right: columns(1);
}

.d-history-block-a__photo-a,
.d-history-block-a__photo-b {
    width: columns(6);
    @include breakpoint(md) {
        width: columns(3);
    }
}

.d-history-block-a__photo-a {
    top: 0;
    left: 0;
}

.d-history-block-a-photo-a {
    display: block;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
}

.d-history-block-a__photo-b {
    bottom: 0;
    right: 0;
}

.d-history-block-a-photo-b {
    display: block;
    padding-bottom: 132%;
    position: relative;
    width: 100%;
}

.d-history-block-a__shape {
    height: columns(6);
    width: columns(6);
    left: columns(3.8);
    top: columns(1.5);
    @include breakpoint(md) {
        height: columns(3);
        width: columns(3);
        left: columns(1.3);
        top: columns(1);
    }
}

/* History block a */

/* History block b */

.d-history-block--b {
    align-items: center;
    @include breakpoint(md) {
        margin-top: 3rem;
    }
}

.d-history-block-b__content {
    @include breakpoint(md) {
        padding-top: 5.25vw;
    }
    .d-history__title {
        @include breakpoint(md) {
            padding-right: columns(2);
        }
    }
    .d-history__text {
        @include breakpoint(md) {
            padding-left: columns(1);
            padding-right: columns(1);
        }
    }
}

.d-history-block-b__photo-box {
    padding-bottom: 76vw;
    position: relative;
    @include breakpoint(md) {
        padding-bottom: 27.864583333vw;
    }
}

.d-history-block-b__photos {
    @include breakpoint(md) {
        padding-right: columns(1);
        padding-left: columns(1);
    }
}

.d-history-block-b__photo-a {
    width: columns(11);
    @include breakpoint(md) {
        width: columns(4);
    }
}

.d-history-block-b__photo-a {
    top: 0;
    left: 0;
}

.d-history-block-b-photo-a {
    display: block;
    padding-bottom: 86%;
    position: relative;
    width: 100%;
}

.d-history-block-b__shape {
    height: columns(5);
    width: columns(5);
    right: 0;
    bottom: columns(-0.3);
    @include breakpoint(md) {
        height: columns(1.75);
        width: columns(1.75);
        left: columns(3.55);
        top: columns(2.1);
    }
}

/* History block b */

/* History block c */

.d-history-block--c {
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    @include breakpoint(md) {
        margin-top: 3rem;
    }
}

.d-history-block-c__content {
    flex: auto;
    @include breakpoint(md) {
        padding-left: columns(1);
        padding-right: columns(5);
        width: columns(12);
        max-width: columns(12);
    }
    .d-history__title {
        @include breakpoint(md) {
            padding-left: columns(1);
        }
    }
    .d-history__text {
        @include breakpoint(md) {
            padding-right: columns(1);
        }
    }
}

.d-history-block-c__photo-box {
    padding-bottom: 52vw;
    position: relative;
    @include breakpoint(md) {
        padding-bottom: 37.76041667vw;
    }
}

.d-history-block-c__photos {
    flex: auto;
    position: relative;
    @include breakpoint(md) {
        padding-left: columns(2);
        padding-right: columns(1);
        width: columns(12);
        max-width: columns(12);
        transform: translateY(-3.90625vw);
    }
}

.d-history-block-c__photo-a {
    bottom: 0;
    left: 0;
    z-index: 2;
    width: columns(8);
    @include breakpoint(md) {
        width: columns(6);
    }
}

.d-history-block-c__photo-b {
    top: 0;
    right: 0;
    width: columns(5);
    @include breakpoint(md) {
        width: columns(4);
    }
}

.d-history-block-c-photo-a {
    display: block;
    padding-bottom: 62%;
    position: relative;
    width: 100%;
}

.d-history-block-c-photo-b {
    display: block;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
}

.d-history-block-c__link.d-default-link {
    @include breakpoint(md) {
        bottom: 2.0833333vw;
        right: 0;
        position: absolute;
    }
}

.d-history-block-c__link--desktop.d-default-link {
    display: none;
    @include breakpoint(md) {
        display: block;
    }
}

.d-history-block-c__link--mobile.d-default-link {
    @include breakpoint(md) {
        display: none;
    }
}

.d-history-block-c__shape {
    height: columns(4);
    width: columns(2);
    right: columns(4);
    top: columns(1.5);
    @include breakpoint(md) {
        height: columns(2.9);
        width: columns(1.25);
        left: columns(6);
        top: columns(1.1);
    }
}

/* History block c */

/* History block d */

.d-history-block--d {
    flex-direction: column;
    flex-wrap: wrap;
}

.d-history-block-d__content {
    flex: auto;
    @include breakpoint(md) {
        padding-left: columns(4);
        padding-right: columns(2);
        width: columns(12);
        max-width: columns(12);
    }
    .d-history__title {
        @include breakpoint(md) {
            padding-right: columns(1);
        }
    }
    .d-history__text,
    .d-history__link {
        @include breakpoint(md) {
            padding-left: columns(1);
        }
    }
}

.d-history-block-d__photo-box {
    flex: auto;
    padding-bottom: 75vw;
    position: relative;
    @include breakpoint(md) {
        padding-bottom: 34.89583333vw;
    }
}

.d-history-block-d__photos {
    @include breakpoint(md) {
        padding-left: columns(1);
        padding-right: columns(5);
        width: columns(12);
        max-width: columns(12);
        transform: translateY(-5.208333333vw);
    }
}

.d-history-block-d__photo-a {
    top: 0;
    left: 0;
    width: columns(6);
    @include breakpoint(md) {
        width: columns(3);
    }
}

.d-history-block-d__photo-b {
    bottom: 0;
    right: 0;
    width: columns(8.5);
    z-index: 2;
    @include breakpoint(md) {
        width: columns(4);
    }
}

.d-history-block-d-photo-a {
    display: block;
    padding-bottom: 115%;
    position: relative;
    width: 100%;
}

.d-history-block-d-photo-b {
    display: block;
    padding-bottom: 85%;
    position: relative;
    width: 100%;
}

.d-history-block-d__shape {
    height: columns(7.2);
    left: columns(2.1);
    width: columns(2);
    top: columns(1);
    @include breakpoint(md) {
        height: columns(3.1);
        width: columns(1.9);
    }
}

/* History block d */

.d-history__logo {
    position: absolute;
    bottom: -10.52083333vw;
    right: -10.4166667vw;
}
