.d-events {
    display: block;
    position: relative;
    overflow-x: hidden;
    @include breakpoint(md) {
        padding-top: 9.89583333vw;
    }
}

.d-events__header {
    display: block;
    position: relative;
    z-index: 1000;
}

.d-events-slider {
    color: $color-white;
    display: block;
    margin-left: var(--page-padding);
    margin-right: var(--page-padding);
    padding-top: 2em;
    position: relative;
    @include breakpoint(md) {
        padding-bottom: 90.35416667vw;
        padding-top: 0;
        margin-right: 0;
        margin-top: -7.5520833333vw;
    }
    @include breakpoint(lg) {
        padding-bottom: 70.354167vw;
    }
}

.d-events-slider__item {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    @include breakpoint(md) {
        flex-flow: row nowrap;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
    }
}

.d-events-slider__left {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    @include breakpoint(md) {
        flex-basis: columns(7);
        max-width: columns(7);
    }
}

.d-events-slider__right {
    display: none;
    @include breakpoint(md) {
        display: block;
        flex-basis: calc(#{columns(5)} + #{var(--page-padding)});
        max-width: calc(#{columns(5)} + #{var(--page-padding)});
    }
}

.d-events-slider__caption {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 8em;
    padding-right: var(--page-padding);
    padding-top: 14.32291667vw;
    position: relative;
    overflow: hidden;
    .d-text {
        margin-left: 1.1rem;
    }
    @include breakpoint(md) {
        padding-bottom: 0;
        padding-right: columns(2);
    }
}

.d-events-slider-caption__item--full {
    flex-basis: 100%;
    margin-bottom: 2.222rem;
    max-width: 100%;
}

.d-events-slider-caption__item--half {
    flex-basis: 50%;
    max-width: 50%;
    @include breakpoint(md) {
        flex-basis: columns(2);
        max-width: columns(2);
    }
}

.d-events-slider-caption__item--half {
    @include breakpoint(md) {
        margin-right: 1rem;
    }
}

.d-events-slider__image--1 {
    bottom: 0;
    margin-top: 2.6rem;
    position: absolute;
    overflow: hidden;
    width: columns(3);
    display: none;
    @include breakpoint(md) {
        display: block;
    }
}

.d-events-slider-image-1 {
    background-color: green;
    padding-top: 100%;
    position: relative;
    will-change: transform;
}

.d-events-slider__image--2 {
    display: block;
    position: relative;
}

.d-events-slider-image-2__image {
    overflow: hidden;
    position: relative;
    z-index: 2;
    .d-image-animate-in__image {
        z-index: 2;
    }
}

.d-events-slider-image-2 {
    background-color: pink;
    display: block;
    position: relative;
    padding-bottom: 55.10416667vw;
    z-index: 2;
    will-change: transform;
}

.d-events-slider__title {
    display: none;
    width: 55.10416667vw;
    padding-bottom: 0.8rem;
    position: absolute;
    top: 100%;
    left: -9.4444vw;
    margin-top: -1rem;
    overflow: hidden;
    text-align: center;
    //transform: translate(-9.8vw, 13.02083333vw) rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: 0 0;
    z-index: 1000;
    @include breakpoint(md) {
        display: block;
    }
}

.d-events-slider__mobile-title {
    margin-top: 0.88em;
    br {
        display: none;
    }
    @include breakpoint(md) {
        display: none;
    }
}

.d-events-slider-title {
    display: inline-block;
    text-align: left;
}

.d-events-slider__item:not(.active) {
    .d-events-slider-title {
        opacity: 0;
        transform: translateY(100%);
    }
    .d-events-slider__caption {
        opacity: 0;
        transform: translateY(6%);
    }
}

.d-events-slider__nav {
    display: flex;
    margin-left: auto;
    position: relative;
    z-index: 1000;
    width: 100%;
    @include breakpoint(md) {
        bottom: 10.80208333vw;
        padding-left: columns(4);
        padding-right: 3.5vw;
        position: absolute;
        width: columns(7);
        justify-content: flex-end;
    }
}

.d-events-slider-nav__item {
    cursor: pointer;
}

.d-events-slider-nav-left {
    margin-right: columns(0.65);
}

.d-events-slider-nav-right {
    margin-left: columns(0.65);
}

.d-events__link.d-default-link {
    bottom: -1.5625vw;
    color: $color-white;
    position: absolute;
    right: var(--page-padding);
    display: none;
    @include breakpoint(md) {
        display: block;
    }
}

.d-events-slider-image__overlay,
.d-events-slider-caption__overlay {
    background-color: $color-black;
    position: absolute;
    left: -5px;
    right: -5px;
    bottom: -5px;
    top: -5px;
    z-index: 0;
}
