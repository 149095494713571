// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// GENERAL
// -----------------------------------------------------------------------------
// Relative URL where all assets are served from.
$base-url: "../../" !default;

// STRUCTURE
// -----------------------------------------------------------------------------
// Breakpoints
$breakpoints: (
    "xs": 0,
    "sm": 576,
    "md": 769,
    "lg": 992,
    "xl": 1200,
) !default;

// Max width for a container.
$container-max-width: 1920px !default;
// Max-width for long text.
$container-max-width-text: 840px !default;

// Padding
$padding: 20px;
$padding-sm: 40px;
$padding-md: 60px;
$padding-lg: 80px;
$padding-xl: 100px;

// COLOURS
// -----------------------------------------------------------------------------
$color-white: #fff;
$color-black: #000;
$color-accent: #fce800;

// GRID
// -----------------------------------------------------------------------------
$columns: 20 !default;
$gap-default: 16px !default;
$gaps-extra: (
    // 'grid': (
        //     '': 15px,
        //     'sm': 26px,
        //     'md': 36px,
        //     'lg': 44px,
        //     '1400px': 60px
        // )
);

// DEFAULT TYPOGRAPHY
// -----------------------------------------------------------------------------
// Default font-size applied to the body (+ optional line-heights)
$body-fontsize-design: 24px;
$font-sizes: (
    // '': (16px, 1.65),
        // 'lg': 17px,
        // '1400px': 17px
);

// CUSTOM TYPOGRAPHY
// -----------------------------------------------------------------------------
// Fontstacks, with custom fonts from base/_fonts.scss
$fontstack-default: "Sen", sans-serif;
$fontstack-bold: "Quincy CF Black", "Helvetica Neue", "Helvetica", "Arial",
    sans-serif !default;

// OTHER VARIABLES
// -----------------------------------------------------------------------------
$page-padding: 7.291666667vw;
$page-padding-mobile: 6vw;
