/* purgecss start ignore */
/* purgecss end ignore */
@import '~hamburgers/dist/hamburgers.css';
@import '~tiny-slider/dist/tiny-slider.css';
@import "~splitting/dist/splitting.css";
@import "~splitting/dist/splitting-cells.css";
*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

html {
  width: 100%;
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin: 0;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0;
}

blockquote {
  margin: 0;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin: 0;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #e0eaf5;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin: 0;
}

button {
  border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* purgecss start ignore */
body.hidden-scroll {
  overflow: hidden;
}

.sl-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  display: none;
  z-index: 1035;
}

.sl-wrapper {
  z-index: 1040;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
}
.sl-wrapper * {
  box-sizing: border-box;
}
.sl-wrapper button {
  border: 0 none;
  background: transparent;
  font-size: 28px;
  padding: 0;
  cursor: pointer;
}
.sl-wrapper button:hover {
  opacity: 0.7;
}
.sl-wrapper .sl-close {
  display: none;
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 10060;
  margin-top: -14px;
  margin-right: -14px;
  height: 44px;
  width: 44px;
  line-height: 44px;
  font-family: Arial, Baskerville, monospace;
  color: #000;
  font-size: 3rem;
}
.sl-wrapper .sl-counter {
  display: none;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 1060;
  color: #000;
  font-size: 1rem;
}
.sl-wrapper .sl-navigation {
  width: 100%;
  display: none;
}
.sl-wrapper .sl-navigation button {
  position: fixed;
  top: 50%;
  margin-top: -22px;
  height: 44px;
  width: 22px;
  line-height: 44px;
  text-align: center;
  display: block;
  z-index: 10060;
  font-family: Arial, Baskerville, monospace;
  color: #000;
}
.sl-wrapper .sl-navigation button.sl-next {
  right: 5px;
  font-size: 2rem;
}
.sl-wrapper .sl-navigation button.sl-prev {
  left: 5px;
  font-size: 2rem;
}
@media (min-width: 35.5em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }
  .sl-wrapper .sl-navigation button.sl-next {
    right: 10px;
    font-size: 3rem;
  }
  .sl-wrapper .sl-navigation button.sl-prev {
    left: 10px;
    font-size: 3rem;
  }
}
@media (min-width: 50em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }
  .sl-wrapper .sl-navigation button.sl-next {
    right: 20px;
    font-size: 3rem;
  }
  .sl-wrapper .sl-navigation button.sl-prev {
    left: 20px;
    font-size: 3rem;
  }
}
.sl-wrapper.sl-dir-rtl .sl-navigation {
  direction: ltr;
}
.sl-wrapper .sl-image {
  position: fixed;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 10000;
}
.sl-wrapper .sl-image img {
  margin: 0;
  padding: 0;
  display: block;
  border: 0 none;
  width: 100%;
  height: auto;
}
@media (min-width: 35.5em) {
  .sl-wrapper .sl-image img {
    border: 0 none;
  }
}
@media (min-width: 50em) {
  .sl-wrapper .sl-image img {
    border: 0 none;
  }
}
.sl-wrapper .sl-image iframe {
  background: #000;
  border: 0 none;
}
@media (min-width: 35.5em) {
  .sl-wrapper .sl-image iframe {
    border: 0 none;
  }
}
@media (min-width: 50em) {
  .sl-wrapper .sl-image iframe {
    border: 0 none;
  }
}
.sl-wrapper .sl-image .sl-caption {
  display: none;
  padding: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  font-size: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.sl-wrapper .sl-image .sl-caption.pos-top {
  bottom: auto;
  top: 0;
}
.sl-wrapper .sl-image .sl-caption.pos-outside {
  bottom: auto;
}
.sl-wrapper .sl-image .sl-download {
  display: none;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #000;
  z-index: 1060;
}

.sl-spinner {
  display: none;
  border: 5px solid #333;
  border-radius: 40px;
  height: 40px;
  left: 50%;
  margin: -20px 0 0 -20px;
  opacity: 0;
  position: fixed;
  top: 50%;
  width: 40px;
  z-index: 1007;
  -webkit-animation: pulsate 1s ease-out infinite;
  -moz-animation: pulsate 1s ease-out infinite;
  -ms-animation: pulsate 1s ease-out infinite;
  -o-animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite;
}

.sl-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.sl-transition {
  transition: -moz-transform ease 200ms;
  transition: -ms-transform ease 200ms;
  transition: -o-transform ease 200ms;
  transition: -webkit-transform ease 200ms;
  transition: transform ease 200ms;
}

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@-moz-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@-o-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@-ms-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
/* purgecss start ignore */
@font-face {
  font-family: "Quincy CF Black";
  src: url("../fonts/Quincy CF Black.woff2") format("woff2"), url("../fonts/Quincy CF Black.woff") format("woff"), url("../fonts/Quincy CF Black.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
/* purgecss end ignore */
/* purgecss start ignore */
html,
body {
  overflow: hidden;
}

:root {
  --page-padding: 6vw;
}
@media screen and (min-width: 769px) {
  :root {
    --page-padding: 7.291666667vw;
  }
}

#app {
  position: relative;
  max-width: 100vw;
}

.d-smoothscrollbar {
  height: 100vh;
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000;
}

.d-intro, .d-history {
  overflow: hidden;
}

/* purgecss start ignore */
html,
body {
  font-family: "Sen", sans-serif;
  line-height: 1.55555em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  line-height: 1.55555em;
}
@media screen and (max-width: 450px) {
  html,
body {
    font-size: 15px;
  }
}
@media screen and (min-width: 450px) {
  html,
body {
    font-size: calc(
        15px +
        9 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 1920px) and (max-width: 2500px) {
  html,
body {
    font-size: calc(
        24px +
        0 *
        ((100vw - 1920px) / 580)
    );
  }
}
@media screen and (min-width: 2500px) {
  html,
body {
    font-size: 24px;
  }
}
html a,
body a {
  text-decoration: none;
  color: currentColor;
}
html b,
html strong,
body b,
body strong {
  font-family: "Quincy CF Black", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: normal;
}

.d-text--small {
  line-height: 1.55555em;
}
@media screen and (max-width: 450px) {
  .d-text--small {
    font-size: 18px;
  }
}
@media screen and (min-width: 450px) {
  .d-text--small {
    font-size: calc(
        18px +
        6 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 1920px) and (max-width: 2500px) {
  .d-text--small {
    font-size: calc(
        24px +
        -4 *
        ((100vw - 1920px) / 580)
    );
  }
}
@media screen and (min-width: 2500px) {
  .d-text--small {
    font-size: 20px;
  }
}

.d-text--bold {
  font-weight: 800;
}

.d-text ul,
.d-text ol {
  padding-left: 20px;
  margin: 0;
}
.d-text a {
  text-decoration: underline;
  color: #fce800;
}

.d-title {
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.d-title--large {
  font-size: 28px;
  font-family: "Sen", sans-serif;
  font-weight: 700;
  line-height: 0.95em;
}
@media screen and (min-width: 576px) {
  .d-title--large {
    font-size: 5.8vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-title--large {
    font-size: 22px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-title--large {
    font-size: calc(
        22px +
        58 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-title--large {
    font-size: 80px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-title--large {
    font-size: 3.3333333333rem;
  }
}

.d-title--medium {
  font-size: 20px;
  line-height: 0.95em;
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-title--medium {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-title--medium {
    font-size: calc(
        18px +
        18 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-title--medium {
    font-size: 36px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-title--medium {
    font-size: 1.5rem;
  }
}

.d-title--xlarge {
  font-size: 36px;
  font-family: "Quincy CF Black", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  line-height: 0.9em;
}
@media screen and (min-width: 576px) {
  .d-title--xlarge {
    font-size: 8vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-title--xlarge {
    font-size: 30px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-title--xlarge {
    font-size: calc(
        30px +
        90 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-title--xlarge {
    font-size: 120px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-title--xlarge {
    font-size: 5rem;
  }
}

.d-title--xxlarge {
  font-size: 5.88vw;
  font-family: "Quincy CF Black", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  line-height: 0.95em;
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-title--xxlarge {
    font-size: 50px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-title--xxlarge {
    font-size: calc(
        50px +
        130 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-title--xxlarge {
    font-size: 180px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-title--xxlarge {
    font-size: 7.5rem;
  }
}

.d-header-link {
  font-size: 16px;
  font-weight: 700;
  font-family: "Sen", sans-serif;
}
@media screen and (min-width: 576px) {
  .d-header-link {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-header-link {
    font-size: 12px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-header-link {
    font-size: calc(
        12px +
        8 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-header-link {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-header-link {
    font-size: 0.8333333333rem;
  }
}

.d-default-link {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.6em;
  font-family: "Sen", sans-serif;
}
@media screen and (min-width: 576px) and (max-width: 450px) {
  .d-default-link {
    font-size: 14px;
  }
}
@media screen and (min-width: 576px) and (min-width: 450px) {
  .d-default-link {
    font-size: calc(
        14px +
        16 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 576px) and (min-width: 1920px) {
  .d-default-link {
    font-size: 30px;
  }
}
@media screen and (min-width: 576px) and (min-width: 1920px) {
  .d-default-link {
    font-size: 1.25rem;
  }
}

.d-label {
  font-size: 20px;
  font-weight: 700;
  line-height: 0.95em;
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-label {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-label {
    font-size: calc(
        18px +
        18 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-label {
    font-size: 36px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-label {
    font-size: 1.5rem;
  }
}

.d-footer-link {
  font-size: 16px;
  font-family: "Sen", sans-serif;
}
@media screen and (min-width: 576px) {
  .d-footer-link {
    font-size: 3vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-footer-link {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-footer-link {
    font-size: calc(
        20px +
        16 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-footer-link {
    font-size: 36px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-footer-link {
    font-size: 1.5rem;
  }
}

.d-footer-legal-link {
  font-size: 13px;
  font-family: "Sen", sans-serif;
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-footer-legal-link {
    font-size: 15px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-footer-legal-link {
    font-size: calc(
        15px +
        1 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-footer-legal-link {
    font-size: 16px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-footer-legal-link {
    font-size: 0.6666666667rem;
  }
}

.d-color--white {
  color: #fff;
}

.d-bg--black {
  background-color: #000;
}

.d-bg--texture {
  background-image: url("../../assets/images/freeridersmc-texture.jpg");
  background-repeat: repeat;
}

@media screen and (max-width: 768px) {
  .d-hide--md {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .d-show--md {
    display: none;
  }
}

.d-type-bold {
  font-weight: 800;
}

.d-header-logo,
.d-footer-logo {
  display: block;
  width: 3.5rem;
}
.d-header-logo svg,
.d-footer-logo svg {
  display: block;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1200px) {
  .d-header-logo,
.d-footer-logo {
    width: 4.3333rem;
  }
}

.d-mobile-menu-logo {
  width: 3.5rem;
}
.d-mobile-menu-logo svg {
  display: block;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1200px) {
  .d-mobile-menu-logo {
    width: 4.3333rem;
  }
}

.d-history-logo {
  width: 38.59375vw;
}
.d-history-logo svg {
  display: block;
  width: 100%;
  height: 100%;
}

.d-img-cover {
  object-fit: cover;
  font-family: "object-fit: cover";
  height: 100%;
  width: 100%;
}

.d-abf {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
}

.d-image-animate-in--from-right.is-hidden .d-image-animate-in__image {
  clip-path: inset(0px 0px 0px 100%);
  transform: translateX(25%);
}
.d-image-animate-in--from-right.is-hidden img {
  transform: scale(1.1);
}

.d-image-animate-in--from-left.is-hidden .d-image-animate-in__image {
  clip-path: inset(0px 100% 0px 0px);
  transform: translateX(-25%);
}
.d-image-animate-in--from-left.is-hidden img {
  transform: scale(1.1);
}

.d-image-animate-in--from-bottom.is-hidden .d-image-animate-in__image {
  clip-path: inset(10% 0px 0px 0px);
  transform: translateY(25%);
}
.d-image-animate-in--from-bottom.is-hidden img {
  transform: scale(1.1);
}

.d-image-animate-in--from-right .d-image-animate-in__image {
  clip-path: inset(0px 0px 0px 0px);
  transform: translateX(0);
  transform-origin: top right;
}
.d-image-animate-in--from-right img {
  transform: scale(1);
}

.d-image-animate-in--from-left .d-image-animate-in__image {
  clip-path: inset(0px 0px 0px 0px);
  transform: translateX(0);
  transform-origin: top left;
}
.d-image-animate-in--from-left img {
  transform: scale(1);
}

.d-image-animate-in--from-bottom .d-image-animate-in__image {
  clip-path: inset(0px 0px 0px 0px);
  transform: translateY(0);
  transform-origin: top center;
}
.d-image-animate-in--from-bottom img {
  transform: scale(1);
}

.d-image-animate-in__image {
  overflow: hidden;
}

.d-gbnf-animate-in__slider.is-hidden .d-gbnf-animate-in__image {
  transform: translateY(300px);
}
.d-gbnf-animate-in__slider.is-hidden .d-gbnf-animate-in__image .d-gbnf-photo-slider-photo {
  clip-path: inset(10% 0px 0px 0px);
}
.d-gbnf-animate-in__slider.is-hidden .d-gbnf-animate-in__image img {
  transform: scale(1.2);
}

.d-gbnf-animate-in__slider.is-hidden .d-gbnf-animate-in__title {
  opacity: 0;
  transform: translateY(300px);
}

.d-image-animate-in__image,
.d-image-animate-in__image img {
  will-change: transform;
  transition: all 2.2s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.d-gbnf-animate-in__title,
.d-gbnf-animate-in__image,
.d-gbnf-animate-in__image img {
  will-change: transform;
  transition: all 1.4s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.d-gbnf-animate-in__title,
.d-gbnf-animate-in__image {
  transition-delay: 0.2s;
}

.d-gbnf-name-slider-name:nth-child(1) .d-gbnf-animate-in__title {
  transition-delay: 0.2s;
}

.d-gbnf-name-slider-name:nth-child(2) .d-gbnf-animate-in__title {
  transition-delay: 0.4s;
}

.d-gbnf-name-slider-name:nth-child(3) .d-gbnf-animate-in__title {
  transition-delay: 0.6s;
}

.d-gbnf-name-slider-name:nth-child(4) .d-gbnf-animate-in__title {
  transition-delay: 0.8s;
}

.d-animate-in__title.is-hidden .word {
  opacity: 0;
  transform: translateY(150px);
}

.d-animate-in__title .word {
  will-change: transform;
  transition: all 1.2s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.d-container {
  padding: 0 var(--page-padding);
}

.d-spacer {
  width: 100%;
}

@media screen and (max-width: 450px) {
  .d-spacer--small {
    height: 20px;
  }
}
@media screen and (min-width: 450px) {
  .d-spacer--small {
    height: calc(
        20px +
        0 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 1920px) {
  .d-spacer--small {
    height: 20px;
  }
}
@media screen and (min-width: 1920px) {
  .d-spacer--small {
    height: 0.8333333333rem;
  }
}

@media screen and (max-width: 450px) {
  .d-spacer--medium {
    height: 35px;
  }
}
@media screen and (min-width: 450px) {
  .d-spacer--medium {
    height: calc(
        35px +
        15 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 1920px) {
  .d-spacer--medium {
    height: 50px;
  }
}
@media screen and (min-width: 1920px) {
  .d-spacer--medium {
    height: 2.0833333333rem;
  }
}

@media screen and (max-width: 450px) {
  .d-spacer--default {
    height: 35px;
  }
}
@media screen and (min-width: 450px) {
  .d-spacer--default {
    height: calc(
        35px +
        40 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 1920px) {
  .d-spacer--default {
    height: 75px;
  }
}
@media screen and (min-width: 1920px) {
  .d-spacer--default {
    height: 3.125rem;
  }
}

@media screen and (max-width: 450px) {
  .d-spacer--large {
    height: 65px;
  }
}
@media screen and (min-width: 450px) {
  .d-spacer--large {
    height: calc(
        65px +
        140 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 1920px) {
  .d-spacer--large {
    height: 205px;
  }
}
@media screen and (min-width: 1920px) {
  .d-spacer--large {
    height: 8.5416666667rem;
  }
}

@media screen and (max-width: 450px) {
  .d-spacer--xlarge {
    height: 80px;
  }
}
@media screen and (min-width: 450px) {
  .d-spacer--xlarge {
    height: calc(
        80px +
        210 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 1920px) {
  .d-spacer--xlarge {
    height: 290px;
  }
}
@media screen and (min-width: 1920px) {
  .d-spacer--xlarge {
    height: 12.0833333333rem;
  }
}

.sl-overlay {
  background-color: #000;
}

.sl-wrapper .sl-close {
  color: #fff;
}

.d-header {
  align-items: center;
  background-color: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 2.2355rem;
  position: relative;
  justify-content: space-between;
  z-index: 9100;
}
@media screen and (min-width: 576px) {
  .d-header {
    padding-top: 2.655555rem;
  }
}
@media screen and (min-width: 769px) {
  .d-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    justify-content: flex-start;
  }
}

.d-header.is-menu-open {
  position: fixed;
  left: 0;
  right: 0;
}
.d-header.is-menu-open .d-header__link {
  opacity: 1;
  pointer-events: none;
}

.d-header-navigation {
  align-items: center;
  display: flex;
  margin-left: 1.2em;
  margin-right: 3.7em;
}
@media screen and (min-width: 769px) {
  .d-header-navigation {
    margin-right: 0;
  }
}
@media screen and (min-width: 992px) {
  .d-header-navigation {
    margin-left: 2.35555em;
  }
}
@media screen and (min-width: 1200px) {
  .d-header-navigation {
    margin-left: 4.35555em;
  }
}

.d-header__link {
  color: #fff;
  margin-right: 1.2rem;
  transition: all 0.3s ease-in-out;
}
.d-header__link:hover {
  color: #fce800;
}
@media screen and (min-width: 769px) {
  .d-header__link {
    margin-right: 1.35rem;
  }
}
@media screen and (min-width: 992px) {
  .d-header__link {
    margin-right: 2.6rem;
  }
}
@media screen and (min-width: 1200px) {
  .d-header__link {
    margin-right: 3.111rem;
  }
}

.d-header__link:last-child {
  margin-right: 0;
}

.d-footer {
  background-color: #000;
  color: #fff;
}

.d-footer__top,
.d-footer__bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .d-footer__top,
.d-footer__bottom {
    flex-direction: row;
    text-align: left;
  }
}

.d-footer__col {
  margin-bottom: 3em;
}
@media screen and (min-width: 769px) {
  .d-footer__col {
    margin-bottom: 0;
  }
}

.d-footer__label {
  font-size: 16px;
  font-weight: 700;
  font-weight: normal;
}
@media screen and (min-width: 576px) {
  .d-footer__label {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-footer__label {
    font-size: 12px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-footer__label {
    font-size: calc(
        12px +
        8 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-footer__label {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-footer__label {
    font-size: 0.8333333333rem;
  }
}

.d-footer__label.d-footer-label--bold {
  font-weight: 700;
}

.d-footer__link {
  font-size: 16px;
  font-weight: bold;
}
@media screen and (min-width: 576px) {
  .d-footer__link {
    font-size: 3vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-footer__link {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-footer__link {
    font-size: calc(
        20px +
        16 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-footer__link {
    font-size: 36px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-footer__link {
    font-size: 1.5rem;
  }
}

.d-footer__bottom,
.d-legal-link {
  font-size: 13px;
  font-weight: normal;
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-footer__bottom,
.d-legal-link {
    font-size: 15px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-footer__bottom,
.d-legal-link {
    font-size: calc(
        15px +
        1 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-footer__bottom,
.d-legal-link {
    font-size: 16px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-footer__bottom,
.d-legal-link {
    font-size: 0.6666666667rem;
  }
}

.d-footer__logo {
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .d-footer__logo {
    margin: 0;
  }
}

.d-legal-link {
  margin-left: 5.7vw;
}
@media screen and (max-width: 768px) {
  .d-legal-link {
    display: block;
    margin-left: 0;
  }
}

.d-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  white-space: wrap;
  cursor: pointer;
  outline: none;
  border: none;
  user-select: none;
}

.d-hamburger-box {
  align-items: center;
  display: flex;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .d-hamburger-box {
    width: 30px;
  }
}
@media screen and (min-width: 768px) {
  .d-hamburger-box {
    width: calc(
        30px +
        6 *
        ((100vw - 768px) / 1152)
    );
  }
}
@media screen and (min-width: 1920px) and (max-width: 2500px) {
  .d-hamburger-box {
    width: calc(
        36px +
        2 *
        ((100vw - 1920px) / 580)
    );
  }
}
@media screen and (min-width: 2500px) {
  .d-hamburger-box {
    width: 38px;
  }
}

.d-hamburger-button {
  opacity: 1 !important;
  top: 3em;
  right: calc(var(--page-padding) - 0.9em);
  position: fixed;
  z-index: 9000;
}
@media screen and (min-width: 576px) {
  .d-hamburger-button {
    top: 3.5em;
  }
}

.d-hamburger-button .d-hamburger-button__inner,
.d-hamburger-button.is-active .d-hamburger-button__inner {
  background-color: #fce800;
}
.d-hamburger-button .d-hamburger-button__inner:before, .d-hamburger-button .d-hamburger-button__inner:after,
.d-hamburger-button.is-active .d-hamburger-button__inner:before,
.d-hamburger-button.is-active .d-hamburger-button__inner:after {
  background-color: #fce800;
}

.d-hamburger-button__inner {
  border-radius: 0;
  height: 2px;
  width: 100%;
}
.d-hamburger-button__inner:before, .d-hamburger-button__inner:after {
  border-radius: 0;
  height: 2px;
  width: 100%;
}
.d-hamburger-button__inner:before {
  top: -9px;
}
.d-hamburger-button__inner:after {
  bottom: -9px;
}

.d-events {
  display: block;
  position: relative;
  overflow-x: hidden;
}
@media screen and (min-width: 769px) {
  .d-events {
    padding-top: 9.89583333vw;
  }
}

.d-events__header {
  display: block;
  position: relative;
  z-index: 1000;
}

.d-events-slider {
  color: #fff;
  display: block;
  margin-left: var(--page-padding);
  margin-right: var(--page-padding);
  padding-top: 2em;
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-events-slider {
    padding-bottom: 90.35416667vw;
    padding-top: 0;
    margin-right: 0;
    margin-top: -7.5520833333vw;
  }
}
@media screen and (min-width: 992px) {
  .d-events-slider {
    padding-bottom: 70.354167vw;
  }
}

.d-events-slider__item {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
}
@media screen and (min-width: 769px) {
  .d-events-slider__item {
    flex-flow: row nowrap;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}

.d-events-slider__left {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
@media screen and (min-width: 769px) {
  .d-events-slider__left {
    flex-basis: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 7);
    max-width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 7);
  }
}

.d-events-slider__right {
  display: none;
}
@media screen and (min-width: 769px) {
  .d-events-slider__right {
    display: block;
    flex-basis: calc(calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 5) + var(--page-padding));
    max-width: calc(calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 5) + var(--page-padding));
  }
}

.d-events-slider__caption {
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 8em;
  padding-right: var(--page-padding);
  padding-top: 14.32291667vw;
  position: relative;
  overflow: hidden;
}
.d-events-slider__caption .d-text {
  margin-left: 1.1rem;
}
@media screen and (min-width: 769px) {
  .d-events-slider__caption {
    padding-bottom: 0;
    padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2);
  }
}

.d-events-slider-caption__item--full {
  flex-basis: 100%;
  margin-bottom: 2.222rem;
  max-width: 100%;
}

.d-events-slider-caption__item--half {
  flex-basis: 50%;
  max-width: 50%;
}
@media screen and (min-width: 769px) {
  .d-events-slider-caption__item--half {
    flex-basis: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2);
    max-width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2);
  }
}

@media screen and (min-width: 769px) {
  .d-events-slider-caption__item--half {
    margin-right: 1rem;
  }
}

.d-events-slider__image--1 {
  bottom: 0;
  margin-top: 2.6rem;
  position: absolute;
  overflow: hidden;
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 3);
  display: none;
}
@media screen and (min-width: 769px) {
  .d-events-slider__image--1 {
    display: block;
  }
}

.d-events-slider-image-1 {
  background-color: green;
  padding-top: 100%;
  position: relative;
  will-change: transform;
}

.d-events-slider__image--2 {
  display: block;
  position: relative;
}

.d-events-slider-image-2__image {
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.d-events-slider-image-2__image .d-image-animate-in__image {
  z-index: 2;
}

.d-events-slider-image-2 {
  background-color: pink;
  display: block;
  position: relative;
  padding-bottom: 55.10416667vw;
  z-index: 2;
  will-change: transform;
}

.d-events-slider__title {
  display: none;
  width: 55.10416667vw;
  padding-bottom: 0.8rem;
  position: absolute;
  top: 100%;
  left: -9.4444vw;
  margin-top: -1rem;
  overflow: hidden;
  text-align: center;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  z-index: 1000;
}
@media screen and (min-width: 769px) {
  .d-events-slider__title {
    display: block;
  }
}

.d-events-slider__mobile-title {
  margin-top: 0.88em;
}
.d-events-slider__mobile-title br {
  display: none;
}
@media screen and (min-width: 769px) {
  .d-events-slider__mobile-title {
    display: none;
  }
}

.d-events-slider-title {
  display: inline-block;
  text-align: left;
}

.d-events-slider__item:not(.active) .d-events-slider-title {
  opacity: 0;
  transform: translateY(100%);
}
.d-events-slider__item:not(.active) .d-events-slider__caption {
  opacity: 0;
  transform: translateY(6%);
}

.d-events-slider__nav {
  display: flex;
  margin-left: auto;
  position: relative;
  z-index: 1000;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .d-events-slider__nav {
    bottom: 10.80208333vw;
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 4);
    padding-right: 3.5vw;
    position: absolute;
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 7);
    justify-content: flex-end;
  }
}

.d-events-slider-nav__item {
  cursor: pointer;
}

.d-events-slider-nav-left {
  margin-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 0.65);
}

.d-events-slider-nav-right {
  margin-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 0.65);
}

.d-events__link.d-default-link {
  bottom: -1.5625vw;
  color: #fff;
  position: absolute;
  right: var(--page-padding);
  display: none;
}
@media screen and (min-width: 769px) {
  .d-events__link.d-default-link {
    display: block;
  }
}

.d-events-slider-image__overlay,
.d-events-slider-caption__overlay {
  background-color: #000;
  position: absolute;
  left: -5px;
  right: -5px;
  bottom: -5px;
  top: -5px;
  z-index: 0;
}

.d-gbnf {
  position: relative;
  overflow-x: hidden;
}

.d-gbnf__header {
  text-align: center;
}

.d-gbnf-name-slider,
.d-gbnf-photo-slider {
  height: 55.46875vw;
  width: 100vw;
}

.d-gbnf-name-slider {
  position: relative;
}

.d-gbnf-photo-slider {
  position: absolute;
  top: 26vw;
}
@media screen and (min-width: 769px) {
  .d-gbnf-photo-slider {
    top: 24.04166667vw;
  }
}

.d-gbnf-name-slider__item,
.d-gbnf-photo-slider__item {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  will-change: transform;
}

.d-gbnf-name-slider__item:not(.active) .d-gbnf-name-slider-name__inner {
  opacity: 0;
  transform: translateY(100%);
}
.d-gbnf-name-slider__item:not(.active) .d-gbnf-name-slider__lifespan {
  opacity: 0;
  transform: translateY(-20%);
}

.d-gbnf-name-slider-item {
  position: relative;
}

.d-gbnf-name-slider__lifespan {
  line-height: 1.55555em;
  color: #fce800;
  font-weight: 700;
  margin-top: -2em;
  text-align: center;
}
@media screen and (max-width: 450px) {
  .d-gbnf-name-slider__lifespan {
    font-size: 18px;
  }
}
@media screen and (min-width: 450px) {
  .d-gbnf-name-slider__lifespan {
    font-size: calc(
        18px +
        6 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 1920px) and (max-width: 2500px) {
  .d-gbnf-name-slider__lifespan {
    font-size: calc(
        24px +
        -4 *
        ((100vw - 1920px) / 580)
    );
  }
}
@media screen and (min-width: 2500px) {
  .d-gbnf-name-slider__lifespan {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) {
  .d-gbnf-name-slider__lifespan {
    margin-top: 0;
  }
}

.d-gbnf-name-slider-name {
  color: #fff;
  display: block;
  width: 200vw;
  text-align: center;
  transform: translateX(-50vw);
}

.d-gbnf-name-slider__name {
  padding-bottom: 10.4166667vw;
}

.d-gbnf-name-slider-name {
  overflow: hidden;
  padding-bottom: 1.4rem;
  font-family: "Quincy CF Black", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 12vw;
  line-height: 0.95em;
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-gbnf-name-slider-name {
    font-size: 40px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-gbnf-name-slider-name {
    font-size: calc(
        40px +
        100 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-gbnf-name-slider-name {
    font-size: 140px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-gbnf-name-slider-name {
    font-size: 5.8333333333rem;
  }
}

.d-gbnf-name-slider-name__inner {
  will-change: transform;
}

.d-gbnf-photo-slider {
  z-index: 900;
}

.d-gbnf-photo-slider__photo {
  position: absolute;
  top: 11vw;
  left: 50vw;
  overflow: hidden;
  width: 60vw;
  transform: translateX(-50%);
}
@media screen and (min-width: 769px) {
  .d-gbnf-photo-slider__photo {
    top: 0;
    width: 42.29166667vw;
  }
}

.d-gbnf-photo-slider-photo {
  height: 60vw;
  position: relative;
  will-change: transform;
}
@media screen and (min-width: 769px) {
  .d-gbnf-photo-slider-photo {
    height: auto;
    padding-bottom: 100%;
  }
}

.d-history {
  padding-top: 12.5vw;
  position: relative;
  overflow-x: hidden;
}

.d-history__sticky-header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@media screen and (min-width: 769px) {
  .d-history__sticky-header--mobile {
    display: none;
  }
}

.d-history__year {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-history__year {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-history__year {
    font-size: calc(
        18px +
        18 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-history__year {
    font-size: 36px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-history__year {
    font-size: 1.5rem;
  }
}

.d-history__block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-history__block {
    flex-direction: row;
  }
}

.d-history__content,
.d-history__photos {
  display: block;
  flex: 1;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .d-history__content,
.d-history__photos {
    flex-basis: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 6);
    max-width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 6);
  }
}

.d-history__content {
  z-index: 10;
}
@media screen and (max-width: 768px) {
  .d-history__content {
    margin-top: 3rem;
    order: 2;
  }
}

@media screen and (max-width: 768px) {
  .d-history__photos {
    margin-top: 3rem;
  }
}

.d-history__photo {
  display: block;
  position: absolute;
  z-index: 1;
}

.d-history__shape {
  background-color: #fce800;
  display: block;
  position: absolute;
  z-index: 0;
}

/* History block a */
.d-history-block--a {
  align-items: center;
}

@media screen and (min-width: 769px) {
  .d-history-block-a__content .d-history__text {
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  }
}
@media screen and (min-width: 769px) {
  .d-history-block-a__content {
    padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  }
}

.d-history-block-a__photo-box {
  padding-bottom: 76vw;
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-history-block-a__photo-box {
    padding-bottom: 38.75vw;
  }
}

.d-history-block-a__photos {
  padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
}

.d-history-block-a__photo-a,
.d-history-block-a__photo-b {
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 6);
}
@media screen and (min-width: 769px) {
  .d-history-block-a__photo-a,
.d-history-block-a__photo-b {
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 3);
  }
}

.d-history-block-a__photo-a {
  top: 0;
  left: 0;
}

.d-history-block-a-photo-a {
  display: block;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.d-history-block-a__photo-b {
  bottom: 0;
  right: 0;
}

.d-history-block-a-photo-b {
  display: block;
  padding-bottom: 132%;
  position: relative;
  width: 100%;
}

.d-history-block-a__shape {
  height: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 6);
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 6);
  left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 3.8);
  top: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1.5);
}
@media screen and (min-width: 769px) {
  .d-history-block-a__shape {
    height: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 3);
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 3);
    left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1.3);
    top: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  }
}

/* History block a */
/* History block b */
.d-history-block--b {
  align-items: center;
}
@media screen and (min-width: 769px) {
  .d-history-block--b {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 769px) {
  .d-history-block-b__content {
    padding-top: 5.25vw;
  }
}
@media screen and (min-width: 769px) {
  .d-history-block-b__content .d-history__title {
    padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2);
  }
}
@media screen and (min-width: 769px) {
  .d-history-block-b__content .d-history__text {
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
    padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  }
}

.d-history-block-b__photo-box {
  padding-bottom: 76vw;
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-history-block-b__photo-box {
    padding-bottom: 27.864583333vw;
  }
}

@media screen and (min-width: 769px) {
  .d-history-block-b__photos {
    padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  }
}

.d-history-block-b__photo-a {
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 11);
}
@media screen and (min-width: 769px) {
  .d-history-block-b__photo-a {
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 4);
  }
}

.d-history-block-b__photo-a {
  top: 0;
  left: 0;
}

.d-history-block-b-photo-a {
  display: block;
  padding-bottom: 86%;
  position: relative;
  width: 100%;
}

.d-history-block-b__shape {
  height: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 5);
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 5);
  right: 0;
  bottom: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * -0.3);
}
@media screen and (min-width: 769px) {
  .d-history-block-b__shape {
    height: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1.75);
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1.75);
    left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 3.55);
    top: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2.1);
  }
}

/* History block b */
/* History block c */
.d-history-block--c {
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-history-block--c {
    margin-top: 3rem;
  }
}

.d-history-block-c__content {
  flex: auto;
}
@media screen and (min-width: 769px) {
  .d-history-block-c__content {
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
    padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 5);
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 12);
    max-width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 12);
  }
}
@media screen and (min-width: 769px) {
  .d-history-block-c__content .d-history__title {
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  }
}
@media screen and (min-width: 769px) {
  .d-history-block-c__content .d-history__text {
    padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  }
}

.d-history-block-c__photo-box {
  padding-bottom: 52vw;
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-history-block-c__photo-box {
    padding-bottom: 37.76041667vw;
  }
}

.d-history-block-c__photos {
  flex: auto;
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-history-block-c__photos {
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2);
    padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 12);
    max-width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 12);
    transform: translateY(-3.90625vw);
  }
}

.d-history-block-c__photo-a {
  bottom: 0;
  left: 0;
  z-index: 2;
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 8);
}
@media screen and (min-width: 769px) {
  .d-history-block-c__photo-a {
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 6);
  }
}

.d-history-block-c__photo-b {
  top: 0;
  right: 0;
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 5);
}
@media screen and (min-width: 769px) {
  .d-history-block-c__photo-b {
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 4);
  }
}

.d-history-block-c-photo-a {
  display: block;
  padding-bottom: 62%;
  position: relative;
  width: 100%;
}

.d-history-block-c-photo-b {
  display: block;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .d-history-block-c__link.d-default-link {
    bottom: 2.0833333vw;
    right: 0;
    position: absolute;
  }
}

.d-history-block-c__link--desktop.d-default-link {
  display: none;
}
@media screen and (min-width: 769px) {
  .d-history-block-c__link--desktop.d-default-link {
    display: block;
  }
}

@media screen and (min-width: 769px) {
  .d-history-block-c__link--mobile.d-default-link {
    display: none;
  }
}

.d-history-block-c__shape {
  height: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 4);
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2);
  right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 4);
  top: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1.5);
}
@media screen and (min-width: 769px) {
  .d-history-block-c__shape {
    height: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2.9);
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1.25);
    left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 6);
    top: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1.1);
  }
}

/* History block c */
/* History block d */
.d-history-block--d {
  flex-direction: column;
  flex-wrap: wrap;
}

.d-history-block-d__content {
  flex: auto;
}
@media screen and (min-width: 769px) {
  .d-history-block-d__content {
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 4);
    padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2);
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 12);
    max-width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 12);
  }
}
@media screen and (min-width: 769px) {
  .d-history-block-d__content .d-history__title {
    padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  }
}
@media screen and (min-width: 769px) {
  .d-history-block-d__content .d-history__text,
.d-history-block-d__content .d-history__link {
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  }
}

.d-history-block-d__photo-box {
  flex: auto;
  padding-bottom: 75vw;
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-history-block-d__photo-box {
    padding-bottom: 34.89583333vw;
  }
}

@media screen and (min-width: 769px) {
  .d-history-block-d__photos {
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
    padding-right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 5);
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 12);
    max-width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 12);
    transform: translateY(-5.208333333vw);
  }
}

.d-history-block-d__photo-a {
  top: 0;
  left: 0;
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 6);
}
@media screen and (min-width: 769px) {
  .d-history-block-d__photo-a {
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 3);
  }
}

.d-history-block-d__photo-b {
  bottom: 0;
  right: 0;
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 8.5);
  z-index: 2;
}
@media screen and (min-width: 769px) {
  .d-history-block-d__photo-b {
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 4);
  }
}

.d-history-block-d-photo-a {
  display: block;
  padding-bottom: 115%;
  position: relative;
  width: 100%;
}

.d-history-block-d-photo-b {
  display: block;
  padding-bottom: 85%;
  position: relative;
  width: 100%;
}

.d-history-block-d__shape {
  height: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 7.2);
  left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2.1);
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2);
  top: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
}
@media screen and (min-width: 769px) {
  .d-history-block-d__shape {
    height: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 3.1);
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1.9);
  }
}

/* History block d */
.d-history__logo {
  position: absolute;
  bottom: -10.52083333vw;
  right: -10.4166667vw;
}

#intro {
  position: relative;
}

.d-intro {
  background-color: #000;
  padding-top: 3.555rem;
  position: relative;
  margin-top: -1px;
  overflow-x: hidden;
}
@media screen and (min-width: 576px) {
  .d-intro {
    padding-top: 6rem;
  }
}
@media screen and (min-width: 769px) {
  .d-intro {
    padding-bottom: 65%;
    padding-top: 0;
    text-align: right;
  }
}
@media screen and (min-width: 1200px) {
  .d-intro {
    padding-bottom: 58%;
  }
}

.d-intro__text {
  display: block;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
  position: relative;
  z-index: 2;
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 11);
}
@media screen and (min-width: 576px) {
  .d-intro__text {
    padding-right: 0;
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 9);
  }
}
@media screen and (min-width: 769px) {
  .d-intro__text {
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
    position: absolute;
    left: var(--page-padding);
    top: 26.3125vw;
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 7.5);
  }
}
@media screen and (min-width: 1200px) {
  .d-intro__text {
    top: 20.3125vw;
  }
}

.d-intro__title {
  text-align: left;
}

.d-intro__link {
  color: #fff;
  margin-top: 2.779rem;
}
@media screen and (min-width: 769px) {
  .d-intro__link {
    margin-right: 3.6665vw;
    margin-top: 4.98958333vw;
  }
}

.d-intro__image {
  position: relative;
  margin-top: -6.666em;
  z-index: 1;
}
@media screen and (min-width: 769px) {
  .d-intro__image {
    position: absolute;
    margin-top: 0;
  }
}

.d-intro-image--right {
  left: 0;
  right: 0;
  bottom: 0;
}
@media screen and (min-width: 769px) {
  .d-intro-image--right {
    left: auto;
    bottom: -4.947916667vw;
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 7);
  }
}

.d-intro-image-right {
  padding-bottom: 65%;
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-intro-image-right {
    padding-bottom: 96%;
  }
}

.d-intro-image--left {
  bottom: -4.427083333vw;
  left: var(--page-padding);
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2);
}

.d-intro-image-left {
  padding-bottom: 100%;
  position: relative;
}

.d-label {
  background-color: transparent;
  display: inline-block;
  padding: 0.7777rem 1.444rem;
  min-width: calc(var(--page-padding) + calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1));
  text-align: center;
}

.d-label--black {
  background-color: #000;
  color: #fff;
}

.d-label--white {
  background-color: #fff;
}

.d-label--yellow {
  background-color: #fce800;
}

.d-default-link {
  display: inline-block;
  position: relative;
}
.d-default-link:after {
  content: "";
  background-color: #fce800;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  transition: width 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: transform;
}
@media screen and (max-width: 768px) {
  .d-default-link:after {
    height: 3px;
  }
}
@media screen and (min-width: 768px) {
  .d-default-link:after {
    height: calc(
        3px +
        1 *
        ((100vw - 768px) / 1152)
    );
  }
}
@media screen and (min-width: 1920px) and (max-width: 2500px) {
  .d-default-link:after {
    height: calc(
        4px +
        0 *
        ((100vw - 1920px) / 580)
    );
  }
}
@media screen and (min-width: 2500px) {
  .d-default-link:after {
    height: 4px;
  }
}
@media screen and (max-width: 768px) {
  .d-default-link:after {
    width: 40px;
  }
}
@media screen and (min-width: 768px) {
  .d-default-link:after {
    width: calc(
        40px +
        35 *
        ((100vw - 768px) / 1152)
    );
  }
}
@media screen and (min-width: 1920px) and (max-width: 2500px) {
  .d-default-link:after {
    width: calc(
        75px +
        0 *
        ((100vw - 1920px) / 580)
    );
  }
}
@media screen and (min-width: 2500px) {
  .d-default-link:after {
    width: 75px;
  }
}
.d-default-link:hover:not(.d-events-slider-nav__item, .d-photo-slider-nav__item):after {
  width: 100%;
}

.d-default-link--large {
  font-size: 16px;
}
@media screen and (min-width: 576px) {
  .d-default-link--large {
    font-size: 3vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-default-link--large {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-default-link--large {
    font-size: calc(
        20px +
        16 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-default-link--large {
    font-size: 36px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-default-link--large {
    font-size: 1.5rem;
  }
}

.d-mobile-menu-overlay {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9000;
}
.d-mobile-menu-overlay.is-hidden {
  display: none;
}

.d-mobile-menu-overlay__background {
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.d-mobile-menu {
  height: 100%;
  margin-top: 150px;
  padding-top: 45px;
  position: relative;
  text-align: center;
  width: 100%;
}
.d-mobile-menu:before {
  content: "";
  background-color: #fff;
  display: block;
  height: 1px;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.d-mobile-menu__link {
  font-size: 6vw;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  margin: 0.3em 0;
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-mobile-menu__link {
    font-size: 30px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-mobile-menu__link {
    font-size: calc(
        30px +
        6 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-mobile-menu__link {
    font-size: 36px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-mobile-menu__link {
    font-size: 1.5rem;
  }
}

.d-photos {
  display: block;
  padding-top: 12.89583333vw;
  position: relative;
}

.d-photo-slider {
  color: #fff;
  padding-bottom: 90vw;
  position: relative;
  margin-bottom: 3.2em;
  margin-right: var(--page-padding);
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-photo-slider {
    margin-bottom: 0;
    padding-bottom: 61.35416667vw;
  }
}

.d-photo-slider__item {
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
}
@media screen and (min-width: 769px) {
  .d-photo-slider__item {
    flex-flow: row nowrap;
  }
}

.d-photo-slider__left {
  display: none;
  flex-basis: calc(calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 5) + var(--page-padding));
  max-width: calc(calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 5) + var(--page-padding));
}
@media screen and (min-width: 769px) {
  .d-photo-slider__left {
    display: block;
  }
}

.d-photo-slider__right {
  padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  position: relative;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .d-photo-slider__right {
    flex-basis: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 7);
    max-width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 7);
  }
}

.d-photo-slider__image--1,
.d-photo-slider__image--2 {
  overflow: hidden;
  position: relative;
}

.d-photo-slider__image--2 {
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 9);
}
@media screen and (min-width: 769px) {
  .d-photo-slider__image--2 {
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 4);
  }
}

.d-photo-slider-image-1 {
  background-color: green;
  padding-bottom: 145%;
  position: relative;
  will-change: transform;
  z-index: 2;
}

.d-photo-slider-image-2 {
  background-color: green;
  padding-bottom: 110%;
  position: relative;
  will-change: transform;
}
@media screen and (min-width: 769px) {
  .d-photo-slider-image-2 {
    padding-bottom: 35.4166667vw;
  }
}

.d-photo-slider__title {
  margin-bottom: 4.9479166667vw;
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 4);
}

.d-photo-slider__nav {
  width: 51.4583333366vw;
  position: absolute;
  top: 100%;
  left: calc(100% - var(--page-padding) - calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 0.5));
  text-align: center;
  transform: rotate(-90deg);
  transform-origin: 0px 0px;
  z-index: 9999;
}
@media screen and (min-width: 769px) {
  .d-photo-slider__nav {
    left: calc(100% - 4.947916665vw);
  }
}

.d-photo-slider-nav__item {
  cursor: pointer;
}

.d-photo-slider-nav__item:nth-child(1) {
  margin-right: 4.947916665vw;
}

.d-photo-slider-nav__item:nth-child(2) {
  margin-left: 4.947916665vw;
}

.d-photo-slider-image__overlay {
  background-color: #000;
  position: absolute;
  left: -5px;
  right: -5px;
  bottom: -5px;
  top: -5px;
  z-index: 0;
}

.d-photo__link {
  margin-left: var(--page-padding);
  top: 100vw;
  z-index: 300;
}
@media screen and (min-width: 769px) {
  .d-photo__link {
    bottom: 0;
    margin-top: 0;
    margin-left: 0;
    position: absolute;
    top: auto;
    left: calc(calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 6) + var(--page-padding));
  }
}

.d-support {
  padding-top: 8.802083333vw;
  position: relative;
  overflow-x: hidden;
}

.d-support__sticky-header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 3em;
}
@media screen and (min-width: 769px) {
  .d-support__sticky-header {
    padding-bottom: 0;
  }
}

.d-support__block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-support__block {
    flex-direction: row;
  }
}

.d-support__content,
.d-support__photos {
  display: block;
  flex: 1;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .d-support__content,
.d-support__photos {
    flex-basis: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 6);
    max-width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 6);
  }
}

@media screen and (min-width: 769px) {
  .d-support__text,
.d-support__cta {
    padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  }
}

.d-support__cta {
  font-size: 16px;
  font-weight: bold;
}
@media screen and (min-width: 576px) {
  .d-support__cta {
    font-size: 3vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-support__cta {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-support__cta {
    font-size: calc(
        20px +
        16 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-support__cta {
    font-size: 36px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-support__cta {
    font-size: 1.5rem;
  }
}

.d-support__photos {
  padding-left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  margin-top: 10.41666667vw;
}
@media screen and (max-width: 768px) {
  .d-support__photos {
    margin-top: 5rem;
    padding-left: 0;
  }
}

.d-support__photo-box {
  padding-bottom: 76vw;
  position: relative;
}
@media screen and (min-width: 769px) {
  .d-support__photo-box {
    padding-bottom: 33.85416667vw;
  }
}

.d-support__photo {
  display: block;
  position: absolute;
  z-index: 1;
}

.d-support__photo-a {
  top: 0;
  left: 0;
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 11);
}
@media screen and (min-width: 769px) {
  .d-support__photo-a {
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 5);
  }
}

.d-support-photo-a {
  display: block;
  padding-bottom: 76%;
  position: relative;
  width: 100%;
}

.d-support__photo-b {
  bottom: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * -0.4);
  right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 3);
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 5);
}
@media screen and (min-width: 769px) {
  .d-support__photo-b {
    right: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2);
  }
}

.d-support-photo-b {
  display: block;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.d-support__link {
  display: inline-block;
  line-height: 1.555em;
  position: relative;
}

.d-support__shape {
  background-color: #fce800;
  display: block;
  position: absolute;
  left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2);
  bottom: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
  height: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 3.1);
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 3);
  z-index: 0;
}
@media screen and (min-width: 769px) {
  .d-support__shape {
    height: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1.1);
    width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1);
    left: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 1.25);
    bottom: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 0.4);
  }
}

.d-support__slider {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  padding: 0 0 0 calc(var(--page-padding) + calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 2));
  position: relative;
}

.d-support-slider__title {
  position: absolute;
  left: var(--page-padding);
  top: 100%;
  transform: rotate(-90deg);
  transform-origin: 0px 0px;
}

.d-support-slider-item__trader {
  font-size: 16px;
  font-weight: 700;
  font-weight: normal;
  margin-bottom: 0.35em;
}
@media screen and (min-width: 576px) {
  .d-support-slider-item__trader {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-support-slider-item__trader {
    font-size: 12px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-support-slider-item__trader {
    font-size: calc(
        12px +
        8 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-support-slider-item__trader {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-support-slider-item__trader {
    font-size: 0.8333333333rem;
  }
}

.d-support-slider-item__title {
  font-size: 16px;
  font-weight: bold;
}
@media screen and (min-width: 576px) {
  .d-support-slider-item__title {
    font-size: 3vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 450px) {
  .d-support-slider-item__title {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) and (min-width: 450px) {
  .d-support-slider-item__title {
    font-size: calc(
        20px +
        16 *
        ((100vw - 450px) / 1470)
    );
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-support-slider-item__title {
    font-size: 36px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1920px) {
  .d-support-slider-item__title {
    font-size: 1.5rem;
  }
}

.d-support-slider__slider {
  width: calc((100vw - (var(--page-padding) * 2) - var(--scrollbar-width)) / 12 * 10);
}

@media screen and (max-width: 768px) {
  .d-support-slider-item {
    padding-left: 2em;
  }
}

.d-support-slider__navigation {
  margin-top: 3.5em;
}
@media screen and (max-width: 768px) {
  .d-support-slider__navigation {
    padding-left: 2em;
  }
}

.d-support-slider-navigation__item {
  cursor: pointer;
}
.d-support-slider-navigation__item:nth-child(1) {
  margin-right: 2.4em;
}