.d-label {
    background-color: transparent;
    display: inline-block;
    padding: 0.7777rem 1.444rem;
    min-width: calc(#{var(--page-padding)} + #{columns(1)});
    text-align: center;
}

.d-label--black {
    background-color: $color-black;
    color: $color-white;
}

.d-label--white {
    background-color: $color-white;
}

.d-label--yellow {
    background-color: $color-accent;
}
