.d-header {
    align-items: center;
    background-color: $color-black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 2.2355rem;
    position: relative;
    justify-content: space-between;
    z-index: 9100;
    @include breakpoint(sm) {
        padding-top: 2.655555rem;
    }
    @include breakpoint(md) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        justify-content: flex-start;
    }
}

.d-header.is-menu-open {
    position: fixed;
    left: 0;
    right: 0;
    .d-header__link {
        opacity: 1;
        pointer-events: none;
    }
}

.d-header-navigation {
    align-items: center;
    display: flex;
    margin-left: 1.2em;
    margin-right: 3.7em;
    @include breakpoint(md) {
        margin-right: 0;
    }
    @include breakpoint(lg) {
        margin-left: 2.35555em;
    }
    @include breakpoint(xl) {
        margin-left: 4.35555em;
    }
}

.d-header__link {
    color: $color-white;
    margin-right: 1.2rem;
    transition: all 0.3s ease-in-out;
    &:hover {
        color: $color-accent;
    }
    @include breakpoint(md) {
        margin-right: 1.35rem;
    }
    @include breakpoint(lg) {
        margin-right: 2.6rem;
    }
    @include breakpoint(xl) {
        margin-right: 3.111rem;
    }
}
.d-header__link:last-child {
    margin-right: 0;
}
