// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Responsive manager
/// @param {String} $breakpoint - Breakpoint
/// @param {Bool} $max - Max-width or min-width
/// @requires $breakpoints or custom breakpoint (e.g. 760px)
/// @link http://sass-guidelin.es/#breakpoint-manager Sass Guidelines - Breakpoint Manager
@mixin breakpoint($breakpoint, $max: false) {
    $query: map-get($breakpoints, $breakpoint);

    @if not $query {
        @if not $max {
            @media screen and (min-width: inspect($breakpoint)) {
                @content;
            }
        } @else {
            @media screen and (max-width: inspect($breakpoint)) {
                @content;
            }
        }
    } @else {
        @if not $max {
            @media screen and (min-width: #{if(type-of($query) == 'string', unquote($query), inspect($query)) + px}) {
                @content;
            }
        } @else {
            @media screen and (max-width: #{if(type-of($query) == 'string', unquote($query), inspect($query - 1)) + px}) {
                @content;
            }
        }
    }
}

/* purgecss start ignore */
@mixin button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    white-space: wrap;
    cursor: pointer;
    outline: none;
    border: none;
    user-select: none;
}

@mixin fontsmoothing() {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin backface-visiblity {
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
}

// Fluid breakpoints
$design-width: 1920px;
$breakpoint-max-mobile: 450px;
$breakpoint-max-tablet: 768px;
$breakpoint-mobile-menu: 1000px;
$breakpoint-wide: 1200px;
$breakpoint-max: 2500px;

//- BASED ON:
/// Mixin: Fluid Type
///
/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $min-size [12px] - Minimum size
/// @param {variable} $target-size [24px] - Maximum size
/// @param {variable} $max-size [24px] - Maximum size
/// @param {variable} $lower-breakpoint [420px] - Stop scaling property smaller at this screen resolution
/// @param {variable} $target-breakpoint [600px] - Exact breakpoint, stops scaling larger than this resolution if no upper-breakpoint is set
/// @param {variable} $upper-breakpoint [900px] - Stop scaling property larger at this screen resolution
/// @example
/// @site https://madebymike.com.au/writing/precise-control-responsive-typography/

// prettier-ignore
@mixin fluid-properties($params, $min-value, $lower-breakpoint, $target-value, $target-breakpoint, $max-value: null, $upper-breakpoint: null) {
    //set min value for sizes below lower-breakpoint
    @media screen and (max-width: $lower-breakpoint) {
        @each $param in $params {
            #{$param}: $min-value;
        }
    }

    //calculate fluid value between the lower breakpoint and the target breakpoint
    @media screen and (min-width: $lower-breakpoint) {
        @each $param in $params {
            #{$param}: fluid-value($min-value, $lower-breakpoint, $target-value, $target-breakpoint );
        }
    }

    //calculate fluid value starting from the target breakpoint and up, stopping at the upper breakpint (if set)
    @if $max-value and $upper-breakpoint {
        @media screen and (min-width: $target-breakpoint) and (max-width: $upper-breakpoint) {
            @each $param in $params {
                #{$param}: fluid-value($target-value, $target-breakpoint, $max-value, $upper-breakpoint );
            }
        }

        @media screen and (min-width: $upper-breakpoint) {
            @each $param in $params {
                #{$param}: $max-value;
            }
        }
    } @else {
        //if no upper breakpoint set, stop scaling at the target breakpoint
        @media screen and (min-width: $target-breakpoint) {
            @each $param in $params {
                #{$param}: $target-value;
            }
        }

        //if no max-value is set, scale param based upon rem value (relative to body font size)
        @media screen and (min-width: $target-breakpoint) {
            @each $param in $params {
                #{$param}: $target-value / $body-fontsize-design * 1rem;
            }
        }
    }
}

// shortcut mixin for fluid properties
// prettier-ignore
@mixin fluid($params, $min-value, $target-value, $max-value: null) {
    @include fluid-properties($params, $min-value, $breakpoint-max-mobile, $target-value, $design-width, $max-value, $breakpoint-max);
}

@mixin type-scale--body-small {
    @include fluid(("font-size"), 18px, $body-fontsize-design, 20px);
    line-height: 1.55555em;
}

@mixin type-scale--body {
    @include fluid(("font-size"), 15px, $body-fontsize-design, 24px);
    line-height: 1.55555em;
}

// prettier-ignore
@mixin type-scale--title-medium {
    font-size: 20px;
    @include breakpoint(md) {
        @include fluid(("font-size"), 18px, 36px);
    }
}

// prettier-ignore
@mixin type-scale--title-large {
    font-size: 28px;
    @include breakpoint(sm) {
        font-size: 5.8vw;
    }
    @include breakpoint(md) {
        @include fluid(("font-size"), 22px, 80px);
    }
}

// prettier-ignore
@mixin type-scale--title-xlarge {
    font-size: 36px;
    @include breakpoint(sm) {
        font-size: 8vw;
    }
    @include breakpoint(md) {
        @include fluid(("font-size"), 30px, 120px);
    }
}

// prettier-ignore
@mixin type-scale--title-xxlarge {
    font-size: 5.88vw;
    @include breakpoint(md) {
        @include fluid(("font-size"), 50px, 180px);
    }
}

// prettier-ignore
@mixin type-scale--header-link {
    font-size: 16px;
    font-weight: 700;
    @include breakpoint(sm) {
        font-size: 18px;
    }
    @include breakpoint(md) {
        @include fluid(("font-size"), 12px, 20px);
    }
}

// prettier-ignore
@mixin type-scale--default-link {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.6em;
    @include breakpoint(sm) {
        @include fluid(("font-size"), 14px, 30px);
    }
}

// prettier-ignore
@mixin type-scale--mobile-menu-link {
    font-size: 6vw;
    @include breakpoint(md) {
        @include fluid(("font-size"), 30px, 36px);
    }
}

// prettier-ignore
@mixin type-scale--footer-link {
    font-size: 16px;
    @include breakpoint(sm) {
        font-size: 3vw;
    }
    @include breakpoint(md) {
        @include fluid(("font-size"), 20px, 36px);
    }
}

// prettier-ignore
@mixin type-scale--footer-legal-link {
    font-size: 13px;
    @include breakpoint(md) {
        @include fluid(("font-size"), 15px, 16px);
    }
}
