.d-color--white {
    color: $color-white;
}

.d-bg--black {
    background-color: $color-black;
}

.d-bg--texture {
    background-image: url($base-url+"assets/images/freeridersmc-texture.jpg");
    background-repeat: repeat;
}

.d-hide--md {
    @include breakpoint(md, true) {
        display: none;
    }
}

.d-show--md {
    @include breakpoint(md) {
        display: none;
    }
}

.d-type-bold {
    font-weight: 800;
}
