// -----------------------------------------------------------------------------
// This file contains styles related to the typography.
// -----------------------------------------------------------------------------

/* purgecss start ignore */
html,
body {
    font-family: $fontstack-default;
    @include type-scale--body;
    @include fontsmoothing;
    color: $color-black;
    line-height: 1.55555em;

    a {
        text-decoration: none;
        color: currentColor;
    }

    b,
    strong {
        font-family: $fontstack-bold;
        font-weight: normal;
    }
}

// Default styles for custom texts.
// This class should be shared between all custom texts.
.d-text--small {
    @include type-scale--body-small;
}

.d-text--bold {
    font-weight: 800;
}

.d-text {
    ul,
    ol {
        padding-left: 20px;
        margin: 0;
    }
    a{
        text-decoration: underline;
        color: $color-accent;
    }

}

.d-title {
    position: relative;
    @include fontsmoothing;
}

.d-title--large {
    @include type-scale--title-large;
    font-family: $fontstack-default;
    font-weight: 700;
    line-height: 0.95em;
}

.d-title--medium {
    @include type-scale--title-medium;
    line-height: 0.95em;
}

.d-title--xlarge {
    @include type-scale--title-xlarge;
    font-family: $fontstack-bold;
    line-height: 0.9em;
}

.d-title--xxlarge {
    @include type-scale--title-xxlarge;
    font-family: $fontstack-bold;
    line-height: 0.95em;
}

.d-header-link {
    @include type-scale--header-link;
    font-family: $fontstack-default;
}

.d-default-link {
    @include type-scale--default-link;
    font-family: $fontstack-default;
}

.d-label {
    @include type-scale--title-medium;
    font-weight: 700;
    line-height: 0.95em;
}

.d-footer-link {
    @include type-scale--footer-link;
    font-family: $fontstack-default;
}

.d-footer-legal-link {
    @include type-scale--footer-legal-link;
    font-family: $fontstack-default;
}
